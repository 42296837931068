<template>
  <div>
    <div class="apply_box_top">
      <div class="apply_box_top_left">
        <div class="apply_box_total">
          <div class="apply_box_total_icon">
            <i class="iconfont icon-fapiaodengji"></i>
          </div>
          <div class="apply_box_total_text apply_box_total_text_special">
            <span>发票总数(张)</span>
            <span class="apply_box_total_text50">{{totalAccount}}</span>
            <span>/</span>
            <span>{{alreadyApplyNum}}</span>
          </div>
        </div>
      </div>
      <div class="apply_box_top_right">
        <div ref="echarts1" class="echarts_box"></div>
      </div>
    </div>
    <div class="table_common">
      <div class="search_flex_box">
        <div class="search_condition search_condition_special">
          <el-form ref="form4" :model="form2">
            <el-form-item label="发票号:" prop="invoiceNo">
              <el-input v-model="form2.invoiceNo" @input="searchInput" maxlength="8"></el-input>
            </el-form-item>
            <el-form-item label="采购商名称:" prop="purchaseName" class="select_class1">
              <el-input v-model="form2.purchaseName"></el-input>
            </el-form-item>
            <el-form-item label="发票日期:" class="select_class2" prop="invoiceDate">
              <el-date-picker v-model="form2.invoiceDate" prefix-icon="iconfont icon-riqi" type="daterange" range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期"
                              end-placeholder="结束日期" :picker-options="applyDateChoose" :editable="false">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="发票评级:" class="select_class" prop="invoiceGrade">
              <el-select v-model="form2.invoiceGrade" placeholder="">
                <el-option v-for="item in optionsGrade" :key="item.index" :label="item.value" :value="item.codeState">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发票确认状态:" class="select_class1" prop="invoiceState">
              <el-select v-model="form2.invoiceState" placeholder="" class="select_class">
                <el-option v-for="item in optionisConfirm" :key="item.index" :label="item.value" :value="item.codeState">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="申请融资状态:" prop="applyFlag">
              <el-select v-model="form2.applyFlag" placeholder="" class="select_class">
                <el-option v-for="item in optionsapplyFlag" :key="item.index" :label="item.value" :value="item.codeState">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="发票付款状态:" prop="payStatus">
              <el-select v-model="form2.payStatus" placeholder="" class="select_class select_pay">
                <el-option v-for="item in optionsState" :key="item.index" :label="item.value" :value="item.codeState">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="search_boxs flex_center_between_box">
          <el-tooltip popper-class="search" effect="light" content="搜索" placement="top">
            <div class="search_button_box" @click="searchForm()">
              <i class="iconfont icon-10sousuo"></i>
            </div>
          </el-tooltip>
          <el-tooltip popper-class="search" effect="light" content="重置" placement="top">
            <div class="reset_button_box" @click="cleargetDate()">
              <i class="iconfont icon-shuaxin"></i>
            </div>
          </el-tooltip>
        </div>
      </div>
      <div class="table_box">
        <div class="table_box_top flex_center">
          <div class="table_box_top_title">申请融资</div>
          <div class="flex_center_end_box">
            <div class="search_button button" @click="addInvoice()">添加发票</div>
            <div class="search_button button data_export">数据导出</div>
            <div class="search_button button" @click="batchApplyFinancing()" :class="[batchClass?'batch_button2':'batch_button1']">批量申请融资</div>
          </div>
        </div>
        <div class="table_box_bottom">
          <el-table ref="treeTable" :data="tableData" stripe border style="width: 100%" row-key="invoiceNo" @selection-change="selectionLineChangeHandle">
            <el-table-column type="selection" align="center" :reserve-selection="true" :resizable="false" class-name="financing_border" :width="selectionWidth" :selectable="selectionLook"
                             ref="multipSection">
            </el-table-column>
            <el-table-column label="发票号" prop="invoiceNo" align="center" show-overflow-tooltip :resizable="false" :width="invoiceWidth" />
            <el-table-column label="采购商名称" prop="purchaseName" align="left" header-align="center" show-overflow-tooltip :resizable="false" :min-width="nameWidth" />
            <el-table-column label="发票日期" prop="invoiceDate" sortable align="center" border show-overflow-tooltip :resizable="false" :width="dateWidth" />
            <el-table-column label="发票金额(元)" prop="invoiceAmount" sortable align="right" header-align="center" :resizable="false" show-overflow-tooltip :width="width138">
              <template slot-scope="scope">
                {{scope.row.invoiceAmount | NumFormat}}
              </template>
            </el-table-column>
            <el-table-column label="发票账期(天)" prop="accPeriod" sortable align="right" header-align="center" :resizable="false" show-overflow-tooltip :width="width138" />
            <el-table-column label="申请融资状态" prop="applyFlag" align="center" show-overflow-tooltip :resizable="false" :width="width120">
              <template slot-scope="scope">
                <div v-if="scope.row.applyFlag == '0'">未申请</div>
                <div v-else>已申请</div>
              </template>
            </el-table-column>
            <el-table-column label="发票确认状态" prop="isApproval" align="center" show-overflow-tooltip :resizable="false" :width="width120">
              <template slot-scope="scope">
                <div v-if="scope.row.isApproval == '0'">未认可</div>
                <div v-else>已认可</div>
              </template>
            </el-table-column>
            <!-- <el-table-column label="发票评级" prop="invoiceGrade" align="center" show-overflow-tooltip :resizable="false" :width="width120"/> -->
            <el-table-column label="发票付款状态" prop="invoiceState" align="center" show-overflow-tooltip :resizable="false" :width="width120">
              <template slot-scope="scope">
                <div v-if="scope.row.invoiceState == '0'">未付款</div>
                <div v-else>已付款</div>
              </template>
            </el-table-column>
            <el-table-column label="操 作" prop="operation" align="center" :resizable="false" :width="width200" fixed="right">
              <template slot-scope="scope">
                <div class="table_oper flex_center_between_box">
                  <el-tooltip class="item_color_money" effect="light" content="申请融资" placement="top" v-if="scope.row.applyFlag == '0'&&scope.row.invoiceState == '0'">
                    <i class="iconfont icon-rongzilishi" @click="applyFinancing(scope.row,scope.$index)"></i>
                  </el-tooltip>
                  <el-tooltip class="item_color_money_complete" effect="light" content="申请融资" placement="top" v-else>
                    <i class="iconfont icon-rongzilishi"></i>
                  </el-tooltip>
                  <el-tooltip class="item_color" effect="light" content="编辑" placement="top" v-if="scope.row.invoiceState == '0'&& scope.row.applyFlag == '0'">
                    <i class="iconfont icon-bianji" @click="editInvoice(scope.row)"></i>
                  </el-tooltip>
                  <el-tooltip class="item_color_money_complete" effect="light" content="编辑" placement="top" v-if="scope.row.invoiceState == '0' && scope.row.applyFlag != '0'">
                    <i class="iconfont icon-bianji"></i>
                  </el-tooltip>
                  <el-tooltip class="item_color" effect="light" content="详情" placement="top" v-else-if="scope.row.invoiceState != '0'">
                    <i class="iconfont icon-shuxingliebiaoxiangqing" @click="look(scope.row)"></i>
                  </el-tooltip>
                  <el-tooltip class="item_delete" effect="light" content="删除" placement="top" v-if="scope.row.applyFlag == '0'">
                    <i class="iconfont icon-shanchu" @click="detelInvoice(scope.row)"></i>
                  </el-tooltip>
                  <el-tooltip class="item_delete nodelete" effect="light" content="删除" placement="top" v-else>
                    <i class="iconfont icon-shanchu"></i>
                  </el-tooltip>
                  <el-tooltip class="item_color" effect="light" content="服务" placement="top">
                    <i class="iconfont icon--fuwu-xianxing"></i>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="" prop="" align="center" :resizable="false" show-overflow-tooltip :width='width30' class-name="add_padding" fixed="right">
              <template slot="header">
                <div class="table_add">
                  <i class="iconfont icon-jiahao"></i>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <Page :totalFont=true :total='page.total' :currentPage='page.pageNo' @onPageChange="onPageChange"></Page>
      </div>
    </div>
    <!-- 添加发票弹框 -->
    <el-dialog title="添加发票" :visible.sync="addInvoiceDialog" center class="add" :close-on-click-modal="false">
      <div class="add_invoice_content">
        <div class="search_invoice">
          <el-input v-model="product.search" placeholder="输入采购商名称搜索">
            <i slot="suffix" class="el-input__icon iconfont icon-10sousuo" @click="getPurchaserList()"></i>
          </el-input>
        </div>
        <div class="choose_invoice">
          <div class="choose_invoice_title flex_center_between_box">
            <div class="">我的采购商:</div>
            <div class="add_f" @click="open()">添加伙伴</div>
          </div>
          <div class="choose_invoice_list">
            <ul>
              <el-tooltip :content="item.coreEnterpriseName" v-for="(item,index) in purchaserList" :key="index" placement="top" effect="light" popper-class="choose_tooltip">
                <li class="choose_invoice_button over" @click="changeStyle(index,item)" ref="choose" :class="state == index?'choose_active_change':'choose_active'">
                  {{item.coreEnterpriseName}}
                </li>
              </el-tooltip>
            </ul>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" class="footer_button1" @click="next()">下一步</el-button>
      </span>
    </el-dialog>
    <!-- 添加发票信息弹框 -->
    <el-dialog :title="addInvoiceMessageTitle" :visible.sync="addInvoiceMessageDialog" center class="add change_padding inner_common_dialog width_1000 " :before-close="handleClose"
               :close-on-click-modal="false">
      <el-form :model="form" ref="form" :rules="rulesInvoice">
        <div class="width_1000_left width_1000_auto">
          <div class="add_invoice_messages inner_common">
            <el-form-item label="采购商名称:">
              <el-input v-model="form.purchaseName" :disabled="nameDisabled"></el-input>
            </el-form-item>
            <el-form-item label="发票号码:" prop="invoiceNo">
              <el-input v-model="form.invoiceNo" maxlength="8" @input="regNumber11">
              </el-input>
            </el-form-item>
            <el-form-item label="发票金额:" prop="invoiceAmount">
              <el-input v-model="form.invoiceAmount" @input="regNumber22" @focus="inputMoneyFocus(form.invoiceAmount)" @blur="inputMoneyBlur(form.invoiceAmount)" maxlength="8"></el-input>
            </el-form-item>
            <el-form-item label="发票日期:" prop="invoiceDate" class="invoice_date_special_class" :class="exceptRepaymentClass1 ? 'exceptRepaymentClass' : ''">
              <el-date-picker v-model="form.invoiceDate" prefix-icon="iconfont icon-riqi" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="applyDateChoose"
                              @change="exceptArrivalChange1()">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="发票账期:" prop="invoiceAccountDate">
              <el-input v-model="form.invoiceAccountDate" @input="regNumber33" maxlength="3">
              </el-input>
            </el-form-item>
            <el-form-item label="发票付款状态:" prop="invoiceState">
              <el-select v-model="form.invoiceState" placeholder="" @change="changeInvoiceState()">
                <el-option v-for="item in optionsState" :key="item.index" :label="item.value" :value="item.codeState">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div class="width_1000_right-gai width_1000_auto">
          <div class="add_invoice_messages inner_common">
            <el-form-item label="付款金额:" prop="paymentAmount" v-show="changeInvoiceStateDis">
              <el-input v-model="form.paymentAmount" @input="regNumber44" @focus="inputMoneypaymentAmount(form.paymentAmount)" @blur="inputMoneyBlurpaymentAmount(form.paymentAmount)" maxlength="8">
              </el-input>
            </el-form-item>
            <el-form-item label="付款金额:" v-show="!changeInvoiceStateDis">
              <el-input v-model="form.paymentAmount" :disabled='true'></el-input>
            </el-form-item>
            <el-form-item label="发票付款日期:" v-show="changeInvoiceStateDis" prop="paymentDate" class="invoice_date_special_class" :class="exceptRepaymentClass ? 'exceptRepaymentClass' : ''">
              <el-date-picker v-model="form.paymentDate" prefix-icon="iconfont icon-riqi" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="applyDateChoose"
                              @change="exceptArrivalChange()">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="发票付款日期:" v-show="!changeInvoiceStateDis">
              <el-input v-model="form.paymentDate" :disabled='true'></el-input>
            </el-form-item>
            <el-form-item label="买家付款凭证:" class="change_width change_flex upload-form" v-if="!changeInvoiceStateDis">
            </el-form-item>
            <el-form-item label="买家付款凭证:" class="change_width change_flex upload-form" prop="paymentVoucher" v-if="changeInvoiceStateDis">
              <el-upload ref="uploadForm" :http-request="handleUploadFile1" action="fakeaction1" v-model="form.paymentVoucher" class="upload-demo" list-type="picture"
                         accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PBG">
                <div class="upload_box">
                  <i class="el-icon-plus"></i>
                </div>
                <span slot="tip" class="el-upload__tip">支持JPG、PNG格式，大小不超过15M</span>
              </el-upload>
            </el-form-item>
            <div v-if="form.paymentVoucher&&changeInvoiceStateDis" class="invoiceUrlClass" style="height:70px">
              <ul>
                <li class="flex_center" style="position:relative">
                  <img :src="jpg + form.paymentVoucher">
                  <div class="close_icon" @click="deleteUpload1()">
                    <i class="iconfont icon-yuyinguanbi"></i>
                  </div>
                </li>
              </ul>
            </div>
            <el-form-item label="买家是否已收货:" class="change_width" prop="isReceived">
              <el-radio-group v-model="form.isReceived">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="买家是否已收发票:" class="change_width" prop="isInvoice">
              <el-radio-group v-model="form.isInvoice">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="上传发票:" class="change_width change_flex upload-form" prop="invoiceUrl">
              <el-upload ref="uploadForm" :http-request="handleUploadFile" action="fakeaction" v-model="form.invoiceUrl" class="upload-demo" list-type="picture"
                         accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PBG">
                <div class="upload_box">
                  <i class="el-icon-plus"></i>
                </div>
                <span slot="tip" class="el-upload__tip">支持JPG、PNG格式，大小不超过15M</span>

              </el-upload>
            </el-form-item>
            <div v-if="form.invoiceUrl" class="invoiceUrlClass">
              <ul>
                <li class="flex_center" style="position:relative">
                  <img :src="jpg + form.invoiceUrl">
                  <div class="close_icon" @click="deleteUpload()">
                    <i class="iconfont icon-yuyinguanbi"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button2" round @click="cancel('form')">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="submit()">提 交</el-button>
      </span>
    </el-dialog>
    <!-- 发票信息弹框 -->
    <el-dialog title="发票详情" :visible.sync="addInvoiceMessageDialogDis" center class=" change_padding add inner_common_dialog addDialog1" :before-close="handleClose" :close-on-click-modal="false">
      <div class="add_invoice_messages">
        <el-form :model="form1">
          <el-row :gutter="20">
            <el-col span="12">
              <div class="grid-content bg-purple-light3">
                <el-form-item label="采购商名称:" class="form_item1">
                  <el-input v-model="form1.purchaseName" disabled=ture></el-input>
                </el-form-item>
                <el-form-item label="发票号码:" class="form_item2">
                  <el-input v-model="form1.invoiceNo" disabled=ture></el-input>
                </el-form-item>
                <el-form-item label="发票金额:" class="form_item2">
                  <el-input v-model="form1.invoiceAmount" type="text" disabled=ture></el-input>
                </el-form-item>
                <el-form-item label="发票日期:" class="form_item2">
                  <el-input v-model="form1.invoiceDate" disabled=ture></el-input>
                </el-form-item>
                <el-form-item label="发票账期:" class="form_item2">
                  <el-input v-model="form1.accPeriod" disabled=ture></el-input>
                </el-form-item>
                <el-form-item label="发票确认状态:" class="form_item2">
                  <el-input v-model="form1.isApproval" disabled=ture></el-input>
                </el-form-item>
                <el-form-item label="发票付款状态:" class="form_item2">
                  <el-input v-model="form1.invoiceState" disabled=ture></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col span="12">
              <div class="grid-content bg-purple-light3">
                <el-form-item label="付款金额:" class="form_item1">
                  <el-input v-model="form1.paymentAmount" disabled=ture></el-input>
                </el-form-item>
                <el-form-item label="发票付款日期:" class="form_item2">
                  <el-input v-model="form1.paymentDate" disabled=ture></el-input>
                </el-form-item>
                <el-form-item label="采购商付款凭证:" class="change_width change_flex form_item2">
                  <ul class="invimg_list flex_wrap">
                    <li class="flex_center">
                      <img :src="jpg + form1.invoiceUrl">
                    </li>
                  </ul>
                </el-form-item>
                <el-form-item label="买家是否已收货:" class="change_width special_disabled form_item2 shop-input">
                  <el-input v-model="form1.isReceived" disabled></el-input>
                </el-form-item>
                <el-form-item label="买家是否已收发票:" class="change_width special_disabled form_item2 shop-input">
                  <el-input v-model="form1.isInvoice" disabled></el-input>
                </el-form-item>
                <el-form-item label="发票图片:" class="change_width change_flex form_item2">
                  <ul class="invimg_list flex_wrap">
                    <li class="flex_center">
                      <img :src="jpg + form1.paymentVoucher">
                    </li>
                  </ul>
                </el-form-item>
              </div>

            </el-col>
          </el-row>

        </el-form>
      </div>
      <span slot="footer" class="dialog-footer-close">
        <el-button class="footer_button1" type="primary" @click="addInvoiceMessageDialogDis = false">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 未评级申请融资弹框 -->
    <el-dialog title="申请融资" :visible.sync="notRatedDialog" center class="dialog_width500" :close-on-click-modal="false">
      <div class="revoke_confirm_box">
        该发票还未确认，请确认后再申请融资。
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button1" type="primary" @click="applyGrade()">申请发票确认</el-button>
      </span>
    </el-dialog>
    <!-- 没有银行账户 -->
    <el-dialog title="完善账户信息" :visible.sync="wszhxx" center class="dialog_width500" :close-on-click-modal="false">
      <div class="revoke_confirm_box">
        请补充完银行账号和收款银行信息后再申请融资。
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button1" type="primary" @click="gopercon()">前往个人中心</el-button>
      </span>
    </el-dialog>
    <!-- 申请融资弹框 -->
    <el-dialog :title="batchTitle" :visible.sync="applyFinancingDialog" center class="add change_padding add_width550" :close-on-click-modal="false" :before-close="handleClose1">
      <div class="add_invoice_message inner_common inner_common_specials" :class="[isClassChange ? 'add_invoice2' : 'add_invoice1']">
        <el-form :model="financingForm" ref="financingForm" :rules="rules">
          <div class="red_warning_box1">
            <div class="red_warning_box flex_center_left red_warning_box2" v-show="redWarning">
              <i class="iconfont icon-jinggao"></i>
              <span>您批量申请的发票中有{{gradeInvoiceNum}}张未确认的发票，请确认后再申请融资。</span>
            </div>
            <div class="red_warning_box flex_center_left" v-show="redWarning1">
              <i class="iconfont icon-jinggao"></i>
              <span>当投资对象是核心企业时，只能选择同一家采购商的发票。</span>
            </div>
          </div>
          <div v-show="batchInvoice" class="red_top">
            <el-form-item label="申请融资发票数量:">
              <el-input v-model="financingForm.financingInvoiceNum" disabled></el-input>
            </el-form-item>
            <el-form-item label="申请融资发票总金额:">
              <el-input v-model="financingForm.financingInvoiceTotalamount" disabled></el-input>
            </el-form-item>
            <el-form-item label="发票关联采购商数量:">
              <el-input v-model="financingForm.financingInvoiceSuppliernum" disabled></el-input>
            </el-form-item>
          </div>
          <el-form-item label="发票号:" v-show="invoiceNOShow">
            <el-input v-model="financingForm.invoiceNo" disabled></el-input>
          </el-form-item>
          <el-form-item label="选择投资对象:" prop="investmentObject">
            <el-select v-model="financingForm.investmentObject" placeholder="" @change="investmentObject">
              <el-option v-for="item in optionsinvestmentObject" :key="item.index" :label="item.value" :value="item.codeState">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="期望融资金额:" prop="financingAmount">
            <el-input v-model="financingForm.financingAmount" :disabled="investorDisShow" @input="regNumber1" @focus="inputMoneyFocusfin(financingForm.financingAmount)"
                      @blur="inputMoneyBlurfin(financingForm.financingAmount)" maxlength="8"></el-input>
          </el-form-item>
          <el-form-item label="期望到账天数:" prop="repaymentDay">
            <!-- <el-date-picker
                        v-model="financingForm.paymentDate"
                        prefix-icon="iconfont icon-riqi"
                        type="date"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions1">
                        </el-date-picker> -->
            <el-input v-model="financingForm.repaymentDay" @input="regNumber2" maxlength="4"></el-input>
          </el-form-item>
          <el-form-item :label="applyRateShow" prop="rate">
            <el-input v-model="financingForm.rate" @input="regNumber3" @change="applyRateChange" maxlength="3"></el-input>
          </el-form-item>
          <el-form-item label="还款日期:" prop="repaymentDate" class="except_date_class" :class="exceptArrivalClass2 ? 'exceptArrivalClass' : ''" v-if="investmentObjectShow && this.boyfirent==2">
            <el-date-picker v-model="financingForm.repaymentDate" prefix-icon="iconfont icon-riqi" type="date" value-format="yyyy-MM-dd" @input="exceptArrivalChange2()"
                            :picker-options="pickerOptions2">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="银行账号:">
            <el-select v-model="financingForm.bankAccount" placeholder="">
              <el-option v-for="item in bankAccountList" :key="item.index" :label="item.label" :value="item.codeState">
              </el-option>
              <!-- <el-option :label="bankAccount" value="1"></el-option> -->
            </el-select>
          </el-form-item>
          <el-form-item label="收款银行:">
            <el-input v-model="financingForm.bankReceiving" disabled></el-input>
          </el-form-item>
          <!-- <el-form-item label="是否提前还款:" class="change_width_upTime" prop="upTime">
                        <el-radio-group v-model="financingForm.upTime">
                            <el-radio label="1">是</el-radio>
                            <el-radio label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button2" round @click="cancel1('financingForm')">取 消</el-button>
        <el-button class="footer_button1 bg_button1" type="primary" @click="submit1()" v-show="submitButton">提 交</el-button>
        <el-button class="footer_button1 bg_button2" v-show="!submitButton">提 交</el-button>
      </span>
    </el-dialog>
    <!-- 添加伙伴 -->
    <el-dialog title="添加伙伴" :visible.sync="applyFinancingDialog1" center class="dialog_width500" :close-on-click-modal="false">
      <div class="add_invoice_message inner_common">
        <el-form :model="addPartner" ref="addPartner" :rules="rules1">
          <el-form-item label="采购商名称:" prop="coreEnterpriseName">
            <el-autocomplete class="inline-input" v-model="addPartner.coreEnterpriseName" :fetch-suggestions="querySearch" :trigger-on-focus="false" @select="handleSelect" maxlength="32">
            </el-autocomplete>
          </el-form-item>
          <el-form-item label="采购商联系人:" prop="contacts">
            <el-input v-model="addPartner.contacts" maxlength="32"></el-input>
          </el-form-item>
          <el-form-item label="电话号码:" prop="phone">
            <el-input v-model="addPartner.phone" @input="regNumber4" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="邮箱账号:" prop="email">
            <el-input v-model="addPartner.email"></el-input>
          </el-form-item>
          <el-form-item label="合作年限:" prop="age">
            <el-select v-model="addPartner.age" placeholder="">
              <el-option v-for="item in options" :key="item.index" :label="item.value" :value="item.codeState">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="合作信息描述:" prop="suppDesc">
            <el-input type="textarea" :autosize="{ minRows: 3, maxRows: 6}" maxlength="200" show-word-limit v-model="addPartner.suppDesc">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button2" round @click="applyFinancingDialog1 = false">取 消</el-button>
        <el-button class="footer_button1" type="primary" @click="applyFinancingDialogFalse('addPartner')">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCoreEnterpriseLists, getInvoiceList, getInvoiceNum, editInvoice, delInvoiceById, financing, findCodeInfoByKey, invoiceGrade, uploads, getSupplierName, getUserInfos, CompanyNameList } from "@/api/supplier.js";
import { mixins1 } from "@/mixins/index";
import Page from '@/components/page'
export default {
  mixins: [mixins1],
  components: { Page },
  data() {
    const valiedateCom = (rule, val, cb) => {
      // let vall = val.split(',')
      // val = ''
      // vall.forEach(item => {
      //     val = val + item.
      // });
      let val1 = val
      // if (val1) {
      //   val1 = val1.toString();
      //   val1 = val1.replace(/,/gi, "");
      //   let numberReg = /^\+?[1-9][0-9]*$/;
      //   if (!numberReg.test(val1)) {
      //     cb(new Error('请输入大于0的整数'))
      //   } else {
      //     cb()
      //   }
      // }
      if (!val) {
        return cb(new Error('请输入期望融资金额'))
      } else if(val === 0 || val === '0'){
        return cb(new Error('期望融资金额不能为0'))
      }else if (parseInt(this.filterOut(val)) > parseInt(this.fapiaomoney)) {
        return cb(new Error('您的融资金额不能高于发票总金额'))
      }
      return cb()
    }
    const valiedateLin = (rule, val, cb) => {
      let val1 = val
      if (val1) {
        val1 = val1.toString();
        val1 = val1.replace(/,/gi, "");
        let numberReg = /^\+?[1-9][0-9]*$/;
        if (!numberReg.test(val1)) {
          cb(new Error('请输入大于0的整数'))
        } else if (val < 8) {
        return cb(new Error('期望到账天数需大于7天'))
      }else {
          cb()
        }
      }
      if (!val || val == null) {
        return cb(new Error('请输入期望到账时间'))
      } else if (val < 8) {
        return cb(new Error('期望到账天数需大于7天'))
      } else if (val > ((new Date(this.financingForm.repaymentDate).getTime() - new Date().getTime()) / 24 / 3600 / 1000) - 30) {
        return cb(new Error('期望到账时间需小于还款日期'))
      }
      return cb()
    }
    const valiedateTel = (rule, val, cb) => {
      if (!val || val == null) {
        return cb(new Error(this.financingForm.investmentObject == '3' ? '请输入折扣率':'请输入期望利率'))
      }  else if (val === 0 || val === '0') {
        return cb(new Error('请输入大于0的数字'))
      }else if ((val < 0) || (val >= 100)) {
        return cb(new Error('请输入0-100之间数字'))
      }
      return cb()
      // let val1 = val
      // if (val1) {
      //   val1 = val1.toString();
      //   val1 = val1.replace(/,/gi, "");
      //   let numberReg = /^\+?[1-9][0-9]*$/;
      //   if (!numberReg.test(val1)) {
      //     cb(new Error('请输入大于0的整数'))
      //   } else {
      //     cb()
      //   }
      // }
      // if (!val) {
      //   return cb(new Error('请输入期望利率'))
      // } else if ((val < 0) || (val >= 100)) {
      //   return cb(new Error('请输入0-100之间数字'))
      // }
      // return cb()
    }
    const valiedateMail = (rule, val, cb) => {
      console.log(val)
      if (!val) {
        return cb(new Error('请输入还款日期'))
      }
      return cb()
    }
    const valiedateinvoiceNo = (rule, val, cb) => {
      if (val) {
        val = val.toString();
        val = val.replace(/,/gi, "");
        let numberReg = /^\+?[1-9][0-9]*$/;
        if (!numberReg.test(val) || (val.length < 8)) {
          cb(new Error('请输入8位为数字的发票号'))
        } else {
          cb()
        }
      } else if (!val) {
        cb(new Error('请输入发票号码'))
      }
    }
    // 整数带千分位金额校验
    const valiedateinvoiceMoney = (rule, value, callback) => {
      if (value) {
        // if (!value) return value;
        value = value.toString();
        value = value.replace(/,/gi, "");
        let numberReg = /^\+?[1-9][0-9]*$/;
        if (!numberReg.test(value)) {
          callback(new Error('请输入大于0的整数'))
        } else {
          callback()
        }
      } else if (!value) {
        callback(new Error('请输入发票金额'))
      }
    }

    const valiedateinvoiceDate = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请输入发票日期'))
      }
      return cb()
    }
    // const valiedateinvoiceTerm = (rule, val, cb) => {
    //     if (!val) {
    //         return cb(new Error('请输入发票账期'))
    //     }
    //     return cb()
    // }
    const valiedateinvoiceState = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请选择发票付款状态'))
      }
      return cb()
    }
    const valiedateresource1 = (rule, val, cb) => {
      console.log('是否已收货的val' + val)
      if (!val) {
        return cb(new Error('请选择是否已收货'))
      }
      return cb()
    }
    const valiedateresource2 = (rule, val, cb) => {

      if (!val) {
        return cb(new Error('请选择是否已收发票'))
      }
      return cb()
    }
    const valiedateupload = (rule, val, cb) => {
      if (!val && this.changeInvoiceStateDis) {
        return cb(new Error('请上传买家付款凭证'))
      }
      return cb()
      // return true
    }
    const valiedateupload1 = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请上传发票'))
      }
      return cb()
    }
    const valiedateInvestment = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请选择资方对象'))
      }
      return cb()
    }
    const valiedateObject = (rule, val, cb) => {
      if (!val) {
        return cb(new Error('请选择资方对象'))
      }
      return cb()
    }
    const invoiceAccountDate = (rule, val, cb) => {
      if (val) {
        val = val.toString();
        val = val.replace(/,/gi, "");
        let numberReg = /^\+?[1-9][0-9]*$/;
        if (!numberReg.test(val)) {
          cb(new Error('请输入大于0的整数'))
        } else {
          cb()
        }
      } else if (!val) {
        cb(new Error('请输入发票账期'))
      }
    }
    const paymentAmount1 = (rule, val, cb) => {
      let val1 = val
      // if (val1) {
      //   let val2 = val1
      //   // val1 = val1.toString();
      //   val2 = val2.toString().replace(/,/gi, "");
      //   let numberReg = /^\+?[1-9][0-9]*$/;
      //   if (!numberReg.test(val2)) {
      //     cb(new Error('请输入大于0的整数'))
      //   } else {
      //     cb()
      //   }
      // }
      // this.form.invoiceAmount = this.filterOut(this.form.invoiceAmount)
      // val1 = this.filterOut(val1)
      if (!val1 && this.changeInvoiceStateDis) {
        return cb(new Error('请填写付款金额'))
      }else if(val1===0){
        return cb(new Error('付款金额不能为0'))
      }
      // val1 = Number(val1)
      val1 = this.filterOut(val1)
      this.form.invoiceAmount = this.filterOut(this.form.invoiceAmount)
      console.log(val1, this.form.invoiceAmount)
      // val1 = ''
      if (Number(val1) > Number(this.form.invoiceAmount)) {
        console.log('ok')
        return cb(new Error('您的付款金额不能大于发票金额'))
      } else {
        cb()
      }
      return cb()
    }
    const paymentDate1 = (rule, val, cb) => {
      if (!val && this.changeInvoiceStateDis) {
        return cb(new Error('请填写付款日期'))
      }
      return cb()
      // return true
    }
    // const valiedateBank = (rule, val, cb) => {
    //     console.log(val)
    //     if (!val) {
    //         return cb(new Error('请选择银行账号'))
    //     }
    //     return cb()
    // }
    return {
      // fileList: [{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}],

      fileList: [],
      fapiaomoney: '',
      bankAccountList: [],
      compressImg: 'https://img2.baidu.com/it/u=3304620759,1323144983&fm=253&fmt=auto&app=138&f=JPEG?w=756&h=500',
      pickerOptions1: {
        disabledDate: (time) => {
          if (this.financingForm.repaymentDate != '') {
            return (
              time.getTime() > new Date(this.financingForm.repaymentDate).getTime() - 30 * 24 * 3600 * 1000 || time.getTime() < Date.now()
            )
          } else {
            return time.getTime() < Date.now()
          }
        }
      },
      pickerOptions2: {
        disabledDate: (time) => {
          if (this.financingForm.repaymentDay) {
            return (
              time.getTime() < new Date().getTime() + (this.financingForm.repaymentDay) * 24 * 3600 * 1000 + 30 * 24 * 3600 * 1000
            )
          } else {
            return time.getTime() < Date.now() + 30 * 24 * 3600 * 1000
          }
        }
      },
      optionsGrade1: [{ id: 3, codekey: "2", value: "A", codeState: "A", descinfo: "发票评级" },
      { id: 4, codekey: "2", value: "B", codeState: "B", descinfo: "发票评级" },
      { id: 5, codekey: "2", value: "C", codeState: "C", descinfo: "发票评级" },
      { id: 6, codekey: "2", value: "未评级", codeState: "未评级", descinfo: "发票评级" }],
      options: [],
      optionsGrade: [],
      optionsState: [],
      optionisConfirm: [],
      optionsapplyFlag: [],
      optionsinvestmentObject: [],
      rules: {
        financingAmount: [
          { required: true, validator: valiedateCom, trigger: 'blur' }
        ],
        repaymentDay: [
          { required: true, validator: valiedateLin, trigger: 'blur' }
        ],
        rate: [
          { required: true, validator: valiedateTel, trigger: 'blur' }
        ],
        repaymentDate: [
          { required: true, validator: valiedateMail, trigger: 'blur' }
        ],
        investmentObject: [
          { required: true, validator: valiedateObject, trigger: 'blur' }
        ],
        // upTime: [
        // { required:true, validator: valiedateupTime, trigger: 'blur' }
        // ],
        // bankAccount: [
        // { required:true, validator: valiedateBank, trigger: 'blur' }
        // ],
      },
      rulesInvoice: {
        invoiceNo: [
          { required: true, validator: valiedateinvoiceNo, trigger: 'blur' }
        ],
        invoiceAmount: [
          { required: true, validator: valiedateinvoiceMoney, trigger: 'blur' }
        ],
        invoiceDate: [
          { required: true, validator: valiedateinvoiceDate, trigger: 'change' }
        ],
        invoiceState: [
          { required: true, validator: valiedateinvoiceState, trigger: 'change' }
        ],
        isReceived: [
          { required: true, validator: valiedateresource1, trigger: 'change' }
        ],
        isInvoice: [
          { required: true, validator: valiedateresource2, trigger: 'change' }
        ],
        invoiceUrl: [
          { required: true, validator: valiedateupload1, trigger: 'blur' }
        ],
        investmentObject: [
          { required: true, validator: valiedateInvestment, trigger: 'blur' }
        ],
        invoiceAccountDate: [
          { required: true, validator: invoiceAccountDate, trigger: 'blur' }
        ],
        paymentAmount: [
          { required: true, validator: paymentAmount1, trigger: 'blur' }
        ],
        paymentDate: [
          { required: true, validator: paymentDate1, trigger: 'blur' }
        ],
        paymentVoucher: [
          { required: true, validator: valiedateupload, trigger: 'blur' }
        ],
      },
      form: {
        purchaseName: '',
        invoiceNo: '',
        invoiceAmount: '',
        invoiceDate: '',
        invoiceState: '0',
        isReceived: '0',
        isInvoice: '0',
        invoiceUrl: '',
        paymentVoucher: '',
        invoiceTime: '',
        invoiceAccountDate: '',
        paymentAmount: '',
        paymentDate: ''
      },
      form1: {
        purchaseName: '',
        invoiceNo: '',
        invoiceAmount: '',
        invoiceDate: '',
        invoiceState: '0',
        paymentVoucher: '',
        invoiceStateqr: '认可',
        isReceived: '是',
        isInvoice: '是',
        invoiceUrl: '',
        paymentAmount: '',
        paymentDate: ''
      },
      form2: {
        payStatus: '',//发票付款状态
        invoiceNo: '',
        purchaseName: '',
        invoiceDate: '',
        invoiceGrade: '',
        invoiceState: '',
        applyFlag: '',
        startDate: '',
        endDate: ''
      },
      financingForm: {
        invoiceNo: '',
        financingAmount: '',
        repaymentDay: '',
        rate: '',
        repaymentDate: '',
        bankAccount: '',
        bankReceiving: '',
        investmentObject: '2',
        upTime: '0',
        financingInvoiceSuppliernum: ''
      },
      tableData: [],
      purchaserList: [],
      product: {
        search: ''
      },
      invoiceList: [
        {
          id: '112233',
          active: true
        },
        {
          id: '223344',
          active: true
        },
        {
          id: '334455',
          active: true
        },
        {
          id: '445566',
          active: true
        },
        {
          id: '556677',
          active: true
        },
        {
          id: '667788',
          active: true
        },
        {
          id: '778899',
          active: true
        },
        {
          id: '889911',
          active: true
        },
        {
          id: '336688',
          active: true
        }
      ],
      list1: [
        {
          value: 0,
          name: '已申请融资发票'
        },
        {
          value: 0,
          name: '未申请融资发票'
        },
      ],
      changeInvoiceStateDis: false,
      addInvoiceMessageDialogDis: false,
      redWarning1: false,
      addInvoiceDialog: false,
      addInvoiceMessageDialog: false,
      applyFinancingDialog: false,
      chooseFinancingInvoiceDialog: false,
      wszhxx: false,
      state: -1,
      invoiceNOShow: true,
      myChart: null,
      totalAccount: 0,
      alreadyApplyNum: 0,
      batchInvoice: false,
      batchTitle: '',
      multipSection: [],
      isClassChange: false,
      nameDisabled: true,
      addInvoiceMessageTitle: '',
      batchClass: false,
      index: [],
      notRatedDialog: false,
      redWarning: false,
      redWarning2: false,
      gradeInvoiceNum: '',
      gradeList: [],
      purchaserListData: [],
      page: {
        pageNo: 1,
        pageSize: 8,
        total: 0
      },
      userid: '',
      id: '',
      coreEnterpriseId: '',
      enterpriseId: '',
      bankAccount: '',
      bankReceiving: '',
      investmentObjectShow: true,
      submitButton: true,
      exceptArrivalClass: false,
      exceptArrivalClass1: false,
      investorDisShow: false,
      applyRateShow: '',
      percent: 0,
      percentStr: 0,
      boyfirent: '',
      exceptRepaymentClass: false,
      exceptRepaymentClass1: false,
      exceptRepaymentClass2: false,
    }
  },
  created() {
    this.getDate()
    this.resizeFn();
    this.tableWidth();
    this.getSelect()
  },
  methods: {
    searchInput(val) {
      val = val.replace(/[^\d]/g, '')
      this.form2.invoiceNo = val
    },
    regNumber11(val) {
      val = val.replace(/[^\d]/g, '')
      this.form.invoiceNo = val
    },
    regNumber22(val) {
      val = val.replace(/[^\d]/g, '')
      this.form.invoiceAmount = val
    },
    regNumber33(val) {
      val = val.replace(/[^\d]/g, '')
      this.form.invoiceAccountDate = val
    },
    regNumber44(val) {
      val = val.replace(/[^\d]/g, '')
      this.form.paymentAmount = val
    },
    regNumber1(val) {
      val = val.replace(/[^\d]/g, '')
      this.financingForm.financingAmount = val
    },
    regNumber2(val) {
      val = val.replace(/[^\d]/g, '')
      this.financingForm.repaymentDay = val
    },
    regNumber3(val) {
      val = val.replace(/[^\d]/g, '')
      this.financingForm.rate = val
    },
    regNumber4(val) {
      val = val.replace(/[^\d]/g, '')
      this.addPartner.phone = val
    },
    exceptArrivalChange() {
      if (this.form.paymentDate) {
        this.exceptRepaymentClass = true
      } else {
        this.exceptRepaymentClass = false
      }
    },
    exceptArrivalChange1() {
      if (this.form.invoiceDate) {
        this.exceptRepaymentClass1 = true
      } else {
        this.exceptRepaymentClass1 = false
      }
    },
    exceptArrivalChange2() {
      if (this.financingForm.repaymentDate) {
        this.exceptArrivalClass1 = true
      }
      else {
        this.exceptArrivalClass1 = false
      }
    },
    changeInvoiceState() {
      if (this.form.invoiceState === '1') {
        this.changeInvoiceStateDis = true
      } else {
        this.form.paymentAmount = ''
        this.form.paymentDate = ''
        this.form.paymentVoucher = ''
        this.changeInvoiceStateDis = false
      }
    },
    investmentObject(val) {
      this.boyfirent = val
      console.log(val)
      this.financingForm.repaymentDay = ''
      this.financingForm.rate = ''
      this.financingForm.repaymentDate = ''
      if (this.batchTitle == '批量申请融资') {
        if (this.financingForm.investmentObject == 2) {
          this.investmentObjectShow = true
          this.redWarning1 = false
          this.submitButton = true
        } else {
          this.investmentObjectShow = false
          if (this.financingForm.financingInvoiceSuppliernum == '1家') {
            this.redWarning1 = false
            this.submitButton = true
          } else {
            this.redWarning1 = true
            this.submitButton = false
          }
        }
      }
      if (this.financingForm.investmentObject == 3) {
        this.investorDisShow = true
        this.financingForm.financingAmount = this.filter(this.fapiaomoney)
        this.applyRateShow = '折扣率(%):'
      } else {
        this.investorDisShow = false
        this.financingForm.financingAmount = this.filter(this.fapiaomoney)
        this.applyRateShow = '期望利率(%):'
      }
      this.$refs.financingForm.clearValidate()
    },
    handleUploadFile(params) {
      console.log(params)
      if (this.form.invoiceUrl == '') {
        const file = params.file
        let form = new FormData();
        form.append("files", file);
        uploads(form).then(res => {
          this.form.invoiceUrl = res[0]
        })
      }
    },
    handleUploadFile1(params) {
      console.log(params)
      console.log(this.form.paymentVoucher)
      if (!this.form.paymentVoucher) {
        const file1 = params.file
        let form = new FormData();
        form.append("files", file1);
        uploads(form).then(res => {
          this.form.paymentVoucher = res[0]
        })
      }
    },
    deleteUpload() {
      this.form.invoiceUrl = ''
    },
    deleteUpload1() {
      this.form.paymentVoucher = ''
    },
    async getSupplierName() {
      let data = await CompanyNameList('comName=' + this.addPartner.coreEnterpriseName)
      this.dataname = data.data
      this.dataname.forEach((item, index) => {
        this.dataname[index].value = item.company_name
      });
    },
    inputMoneyFocus(value) {
      this.form.invoiceAmount = this.filterOut(value)
    },
    inputMoneyBlur(value) {
      this.form.invoiceAmount = this.filter(value)
    },
    inputMoneyFocusfin(value) {
      this.financingForm.financingAmount = this.filterOut(value)
    },
    inputMoneyBlurfin(value) {
      this.financingForm.financingAmount = this.filter(value)
    },
    inputMoneypaymentAmount(value) {
      this.form.paymentAmount = this.filterOut(value)
    },
    inputMoneyBlurpaymentAmount(value) {
      this.form.paymentAmount = this.filter(value)
    },
    async getDate() {
      let datas = localStorage.getItem('userInfo')
      datas = JSON.parse(datas)
      this.userid = datas.userId
      datas = localStorage.getItem('userDate')
      datas = JSON.parse(datas)
      console.log(datas)
      console.log(datas.id)
      datas = await getUserInfos('userId=' + datas.id)
      console.log(datas)
      this.bankAccount = datas.data.bankAccount
      this.bankReceiving = datas.data.bankReceiving
      this.financingForm.bankAccount = datas.data.bankAccount
      this.financingForm.bankReceiving = datas.data.bankReceiving
      this.bankAccountList=[]
      if (this.bankAccount) {
        
        let i = { label: datas.data.bankAccount, codeState: 1 }
        this.bankAccountList.push(i)
      }
      if (this.form2.invoiceDate != null) {
        if (this.form2.invoiceDate[0]) {
          this.form2.startDate = this.form2.invoiceDate.slice(',')[0]
          this.form2.endDate = this.form2.invoiceDate.slice(',')[1]
        } else {
          this.form2.startDate = ''
          this.form2.endDate = ''
        }
      } else {
        this.form2.startDate = ''
        this.form2.endDate = ''
      }
      let data = await getInvoiceList('pageNo=' + this.page.pageNo + '&pageSize=' + this.page.pageSize +
        '&invoiceNo=' + this.form2.invoiceNo + '&purchaseName=' + this.form2.purchaseName + '&startDate=' + this.form2.startDate + '&endDate=' + this.form2.endDate + '&invoiceGrade=' + this.form2.invoiceGrade + '&isConfirm='
        + this.form2.invoiceState + '&applyFlag=' + this.form2.applyFlag + '&invoiceState=' + this.form2.payStatus)
      this.tableData = data.data.records
      this.page.total = data.data.total
      data = await getInvoiceNum()
      this.list1[0].value = data.data.yes_inv_num
      this.list1[1].value = data.data.no_inv_num
      this.totalAccount = data.data.total_num
      this.alreadyApplyNum = data.data.yes_inv_num
      this.$nextTick(function () {
        this.riskEchart2(
          this.fontSize,
          this.echartsWidth,
          this.invoiceWidth,
          this.selectionWidth,
          this.width200,
          this.width30
        )
      })
    },
    gopercon() {
      this.$router.push("/system/personalCenter/accountManagement");
      this.isPersonCenter = true;
      localStorage.setItem("isPersonCenter", true);
    },
    async getSelect() {
      let data = await findCodeInfoByKey('ms=2')
      this.optionsGrade = data.data
      data = await findCodeInfoByKey('ms=3')
      this.optionsState = data.data
      data = await findCodeInfoByKey('ms=4')
      this.optionsapplyFlag = data.data
      data = await findCodeInfoByKey('ms=1')
      this.optionsinvestmentObject = data.data
      data = await findCodeInfoByKey('ms=7')
      this.options = data.data
      data = await findCodeInfoByKey('ms=14')
      this.optionisConfirm = data.data
    },
    searchForm() {
      this.page.pageNo = 1
      this.getDate()
    },
    cleargetDate() {
      this.$refs.form4.resetFields()
      this.page.pageNo = 1
      this.getDate()
    },
    async getPurchaserList() {
      this.purchaserListData = await getCoreEnterpriseLists("supplierName=" + this.product.search)
      this.purchaserList = this.purchaserListData.data
    },
    // 列表点击样式
    changeStyle(index, item) {
      this.form = {
        coreEnterpriseId: "",
        invoiceAccountDate: "",
        invoiceAmount: "",
        invoiceDate: "",
        invoiceNo: "",
        invoiceState: "",
        invoiceUrl: "",
        isInvoice: "",
        isReceived: "",
        paymentAmount: "",
        paymentDate: "",
        paymentVoucher: ""
      }
      this.state = index
      this.coreEnterpriseId = item.coreEnterpriseId
      this.form.purchaseName = item.coreEnterpriseName
    },
    // 下一步
    next() {
      if (this.state != -1) {
        this.addInvoiceDialog = false
        this.addInvoiceMessageDialog = true
        this.product.search = '';
        this.$nextTick(() =>
          this.$refs.form.clearValidate()
        )
        delete this.form.id
        this.changeInvoiceState()
        this.exceptRepaymentClass1 = false
      }
    },
    cancel(formName) {
      this.$refs[formName].resetFields();
      this.addInvoiceMessageDialog = false
      this.addInvoiceDialog = false
    },
    cancel1(formName) {
      this.$refs[formName].resetFields();
      this.applyFinancingDialog = false
    },
    // 申请融资
    applyFinancing(row, index) {
      this.boyfirent = 2
      this.redWarning = false
      this.investorDisShow = false
      this.financingForm.invoiceNo = row.invoiceNo
      this.financingForm.financingInvoiceTotalamount = row.invoiceAmount
      // this.financingForm.bankAccount = '41345267748485999'
      // this.financingForm.bankReceiving = '中国银行恒丰路支行'
      if (row.isApproval == '0') {
        this.notRatedDialog = true
        this.id = row.id
        this.enterpriseId = row.enterpriseId
      } else {
        console.log(row)
        this.batchTitle = '申请融资'
        this.applyFinancingDialog = true
        this.investmentObjectShow = true
        this.invoiceNOShow = true
        this.batchInvoice = false
        this.isClassChange = false
        this.redWarning1 = false
        this.redWarning = false
        this.fapiaomoney = row.invoiceAmount
        
      }
      this.$nextTick(()=>{
        this.$refs.financingForm.resetFields()
        this.financingForm.financingAmount=this.filter(this.fapiaomoney)
      })
      if (this.financingForm.financingInvoiceSuppliernum) {
        delete this.financingForm.financingInvoiceNum
        delete this.financingForm.financingInvoiceSuppliernum
        delete this.financingForm.financingInvoiceTotalamount
      }
      this.applyRateShow = '期望利率(%):'
      // this.financingForm.financingAmount = this.filter(this.fapiaomoney)

    },
    applyRateChange() {
      if (this.financingForm.rate && this.financingForm.investmentObject == 3) {
        var str = (100 - (this.financingForm.rate)).toString()
        var reg = RegExp(/./);
        if (str.match(reg)) {
          this.percent = (str || '').split('.')
          var num = this.percent.join('')
          this.percentStr = '0' + '.' + Number(num)
        }
        this.financingForm.financingAmount = this.fapiaomoney
        this.financingForm.financingAmount = this.financingForm.financingAmount * this.percentStr
        if (isNaN(this.financingForm.financingAmount)) {
          this.financingForm.financingAmount = '--'
        } else if (!isNaN(this.financingForm.financingAmount)) {
          this.financingForm.financingAmount = this.fapiaomoney
          this.financingForm.financingAmount = this.financingForm.financingAmount * this.percentStr
          this.financingForm.financingAmount = this.filter(this.financingForm.financingAmount)
        }
        this.financingForm.financingAmount = this.filter(this.financingForm.financingAmount)
      }
    },
    // 申请评级
    async applyGrade() {
      console.log(this.id)
      let data = await invoiceGrade('invoiceId=' + this.id + '&enterpriseId=' + this.enterpriseId)
      if (data.msg == '申请成功') {
        this.$message({
          message: '发票确认申请已提交',
          type: 'success'
        });
      }
      this.notRatedDialog = false
    },
    // 批量申请融资
    batchApplyFinancing() {
      if (this.multipSection.length > 1) {
        this.applyFinancingDialog = true
        this.investmentObjectShow = true
        this.batchTitle = '批量申请融资'
        this.invoiceNOShow = false
        this.batchInvoice = true
        // this.financingForm.bankAccount = '41345267748485999'
        // this.financingForm.bankReceiving = '中国银行恒丰路支行'
        this.redWarning = false
        this.redWarning1=false
        this.isClassChange = true
        this.applyRateShow = '期望利率(%):'

        this.financingForm.financingInvoiceTotalamount = 0
        let j = 0, i = 0
        let all = this.multipSection
        let alls = []
        let allss = []
        all.forEach((item, index) => {
          if (item.isApproval != '0') {
            alls.push(item)
          }
          if (item.isApproval == '0') {
            allss.push(item)
          }
        })
        this.financingForm.financingInvoiceNum = alls.length + '张'
        all = alls
        this.financingForm.invoiceNo = ''
        console.log("all",all)
        for (i = 0; i < all.length; i++) {
          console.log(this.financingForm.invoiceNo)
          this.financingForm.financingInvoiceTotalamount = parseFloat(JSON.parse(JSON.stringify(all[i].invoiceAmount)))
            + parseFloat(JSON.parse(JSON.stringify(this.financingForm.financingInvoiceTotalamount)))
          this.financingForm.invoiceNo = JSON.parse(JSON.stringify(this.financingForm.invoiceNo)) + ',' + all[i].invoiceNo
        }
        this.financingForm.invoiceNo = this.financingForm.invoiceNo.substr(1)
        for (i = 0; i < all.length; i++) {
          for (j = i + 1; j < all.length; j++) {
            if (all[i].purchaseName === all[j].purchaseName) {
              all.splice(j, 1)
              j = j - 1
            }
          }
        }
        this.financingForm.financingInvoiceTotalamount = this.filter(this.financingForm.financingInvoiceTotalamount)
        this.financingForm.financingInvoiceSuppliernum = all.length + '家'
        for (i = 0; i < this.multipSection.length; i++) {
          if (this.multipSection[i].isApproval == '0') {
            this.redWarning = true
          }
        }
        this.gradeInvoiceNum = allss.length
        this.financingForm.supplierId = this.userid
      }
      this.$nextTick(() =>
        this.$refs.financingForm.resetFields()
      )
      this.fapiaomoney = this.filterOut(this.financingForm.financingInvoiceTotalamount)
      this.$nextTick(()=>{
        this.financingForm.financingAmount = this.filter(this.fapiaomoney)
      })
      
    },
    resizeFn() {
      if (document.body.clientWidth > 1664) {
        this.fontSize = 14
        this.echartsWidth = 36
        this.invoiceWidth = 130
        this.selectionWidth = 40
        this.width200 = 200
        this.width30 = 30
      } else {
        this.fontSize = 10
        this.echartsWidth = 24
        this.invoiceWidth = 87
        this.selectionWidth = 24
        this.width200 = 140
        this.width30 = 20
      }
      for (let i = 0; i < this.tableData.length; i++) {
        if (this.tableData[i].financingInvoiceState == '1') {
          this.tableData[i].iconLookColor = true
        }
      }
    },
    selectionLineChangeHandle(val) {
      this.multipSection = val
      if (this.multipSection.length > 1) {
        this.batchClass = true
      } else {
        this.batchClass = false
      }
    },
    // 添加发票
    addInvoice() {
      this.product.search = ''
      this.state = -1
      this.addInvoiceDialog = true
      this.addInvoiceMessageTitle = '添加发票信息'
      this.getPurchaserList()
    },
    // 编辑发票
    editInvoice(row) {
      this.addInvoiceMessageDialog = true
      this.addInvoiceMessageTitle = '编辑发票信息'
      this.exceptRepaymentClass1 = true
      this.form.invoiceMoney = this.filter(this.form.invoiceMoney)
      this.form = JSON.parse(JSON.stringify(row))
      this.form.coreEnterpriseId = row.enterpriseId
      this.form.investmentObject = '资方'
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
        this.inputMoneyBlur(this.form.invoiceAmount)
      })
      this.changeInvoiceState()
    },
    look(row) {
      this.addInvoiceMessageDialogDis = true
      this.form1 = JSON.parse(JSON.stringify(row))
      this.form1.isApproval = this.form1.isApproval == 1 ? '认可' : '不认可'
      this.form1.invoiceState = this.form1.invoiceState == 1 ? '已付款' : '未付款'
      this.form1.isReceived = this.form1.isReceived == 1 ? '是' : '否'
      this.form1.isInvoice = this.form1.isInvoice == 1 ? '是' : '否'
      this.form1.invoiceAmount = this.filter(this.form1.invoiceAmount)
      this.form1.paymentAmount = this.filter(this.form1.paymentAmount)
    },
    async detelInvoice(row) {
      this.$confirm('您是否确定要删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
              let data = await delInvoiceById('id=' + row.id).then(()=>{
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
              this.getDate()
          })

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });

    },
    // 弹框关闭按钮
    handleClose() {
      this.addInvoiceDialog = false
      this.addInvoiceMessageDialog = false
      this.addInvoiceMessageDialogDis = false
      this.$refs.form.resetFields();
    },
    handleClose1(){
      this.$refs.financingForm.resetFields();
      this.applyFinancingDialog=false
    },
    // 提交

    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.addInvoiceMessageDialog = false
          this.addInvoiceDialog = false
          if (this.addInvoiceMessageTitle == '添加发票信息') {
            this.form.supplierId = this.userid
          }
          this.form.coreEnterpriseId = this.coreEnterpriseId
          this.form.invoiceAmount = this.filterOut(this.form.invoiceAmount)
          if (this.form.paymentAmount) {
            this.form.paymentAmount = this.filterOut(this.form.paymentAmount)
          }
          let data = await editInvoice(this.form)
          if (data.code == 200) {
            this.getDate()
          } else if (data.msg == '发票已存在') {
            this.$message.error('您的发票已添加过，并已被您删除');
          }
          this.$refs.form.resetFields();
        } else {
          return false;
        }
      });
    },
    submit1() {
      if ((!this.bankAccount) && (!this.bankReceiving)) {
        this.wszhxx = true
      } else {
        this.$refs.financingForm.validate(async (valid) => {
          if (valid) {
            this.applyFinancingDialog = false
            if (this.financingForm.financingInvoiceSuppliernum) {
              this.financingForm.financingInvoiceNum = this.financingForm.financingInvoiceNum.split('张')[0]
              this.financingForm.financingInvoiceSuppliernum = this.financingForm.financingInvoiceSuppliernum.split('家')[0]
              this.financingForm.financingInvoiceTotalamount = this.filterOut(this.financingForm.financingInvoiceTotalamount)
            } else {
              this.financingForm.financingInvoiceNum = '1'
            }
            // this.financingForm.financingAmount = this.filterOut(this.financingForm.financingAmount)
            // this.financingForm.financingAmount = this.fapiaomoney
            if (this.financingForm.investmentObject == 3) {
              this.financingForm.financingAmount = this.fapiaomoney
            } else {
              this.financingForm.financingAmount = this.filterOut(this.financingForm.financingAmount)
            }
            let data = await financing(this.financingForm)
            this.$refs.treeTable.clearSelection()
            if (data.code == 200) {
              this.getDate()
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    },
    selectionLook(row, rowIndex) {
      if (row.applyFlag == '1') {
        return false;//禁用
      } else {
        return true;//不禁用
      }
    },
  }
}
</script>

<style scoped>
  ::v-deep .exceptArrivalClass .el-date-editor--date:hover .el-input__prefix {
    display: none;
  }
  ::v-deep .exceptRepaymentClass .el-date-editor--date:hover .el-input__prefix {
    display: none;
  }
  ::v-deep.shop-input.special_disabled
    .el-input.is-disabled
    .el-input__inner.el-input__inner {
    padding-left: 0;
    background: #f7fafc;
    border: 1px solid #f7fafc;
    color: #162747;
  }
  .width_1000_right-gai {
    float: right;
    width: 462px;
    height: 293px;
    padding: 20px;
    background: #f7fafc;
    border-radius: 10px;
  }
  ::v-deep .addDialog .el-dialog {
    width: 1000px;
    height: 630px;
  }
  ::v-deep .addDialog1 .el-dialog {
    width: 1000px;
    height: 600px;
  }
  .dialog-footer {
    margin-top: 14px;
    display: block;
  }
  .dialog-footer-close {
    margin-top: 9px;
    float: right;
  }
  .form_item1 {
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 20px;
  }
  .form_item2 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .form_item3 {
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 200px;
  }
  .bg-purple-light1 {
    width: 440px;
    height: 460px;
    background: #f7fafc;
  }
  .bg-purple-light2 {
    width: 460px;
    height: 460px;
    background: #f7fafc;
    border-radius: 10px;
  }
  .bg-purple-light3 {
    width: 460px;
    height: 450px;
    background: #f7fafc;
    border-radius: 10px;
  }
  .grid-content {
    border-radius: 10px;
    min-height: 36px;
  }
  ::v-deep .select_pay .el-input {
    width: 272px;
  }
  /* ::v-deep .exceptArrivalClass .el-date-editor:hover .el-input__prefix {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            display: none;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        }
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        ::v-deep .exceptArrivalClass .is-success .el-date-editor:hover .el-input__prefix {
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            display: none;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        } */
  .bg_button1 {
    background: #339c9b;
    border: 1px solid #339c9b;
  }
  .bg_button2 {
    background: #dae4e9;
    border: 1px solid #dae4e9;
    cursor: not-allowed;
  }

  ::v-deep .add_invoice_message .el-input__prefix {
    left: 93% !important;
  }
  ::v-deep .except_date_class .el-input__prefix {
    left: 93% !important;
  }
  .invoiceUrlClass {
    margin-left: 108px;
    margin-top: -20px;
  }

  ::v-deep .el-upload-list--picture .el-upload-list__item {
    display: none;
  }
  ::v-deep .table_oper .nodelete {
    color: #e1e7ea !important;
    cursor: not-allowed !important;
  }
  .search_invoice {
    margin-bottom: 17px;
  }
  .choose_invoice_title {
    line-height: 30px;
    margin-bottom: 14px;
  }
  .add_f {
    width: 100px;
    line-height: 30px;
    height: 30px;
    text-align: center;
    margin-right: 20px;
    background: #339c9b;
    border-radius: 20px;
    font-size: 12px;
    color: #ffffff;
    cursor: pointer;
  }
  ::v-deep .el-table .cell:first-child {
    overflow: visible !important;
    text-overflow: clip !important;
  }
  .el-table__body-wrapper {
    text-align: right;
  }
  .apply_box_top {
    display: flex;
    padding-top: 10px;
    margin-bottom: 24px;
  }
  .apply_box_top_left {
    width: 340px;
    height: 176px;
    margin-right: 60px;
    padding: 20px;
    background: #339c9b;
    box-shadow: 0px 10px 20px 0px rgba(47, 160, 159, 0.23);
    border-radius: 14px;
  }
  .apply_box_total {
    display: flex;
    align-items: center;
    width: 300px;
    height: 136px;
    padding-top: 20px;
    padding-bottom: 24px;
    padding-left: 16px;
    padding-right: 20px;
    background: rgba(237, 242, 245, 0.2);
    box-shadow: 0px 4px 8px 0px rgba(0, 102, 101, 0.23);
    border-radius: 14px;
  }
  .apply_box_total i {
    flex: 1;
    margin-right: 30px;
    color: #ffffff;
    font-size: 34px;
  }
  .apply_box_top_right {
    flex: 1;
  }
  .echarts_box {
    height: 250px;
    margin-top: -40px;
  }
  .search_condition {
    flex: 1;
  }
  .search_flex_box >>> .el-form-item {
    margin-bottom: 20px;
  }
  .search_flex_box >>> .el-form-item__content {
    float: left;
    width: 300px;
  }
  .search_flex_box >>> .el-input__inner {
    height: 30px;
  }
  .search_flex_box >>> .el-form-item:last-child {
    margin-bottom: 0;
  }
  .search_flex_box >>> .el-form-item:nth-child(3) {
    margin-right: 0;
  }
  .search_flex_box >>> .el-date-editor--daterange.el-input__inner {
    width: 300px;
  }
  .search_flex_box >>> .el-date-editor .el-range-separator {
    display: none;
  }
  .search_flex_box >>> .el-range-input {
    width: 80%;
  }
  .table_box_top {
    margin-bottom: 14px;
  }
  .table_box_top_title {
    flex: 1;
  }
  .table_box_top_button div:last-child {
    margin-right: 0;
  }
  .table_total_data {
    flex: 1;
  }
  .table_total_data {
    font-size: 12px;
  }
  .choose_active {
    color: #162747;
    background: #ffffff;
  }
  .choose_active_change {
    color: #ffffff;
    background: #339c9b;
  }
  .add_invoice_messages >>> .el-form-item__content {
    float: left;
  }
  .add_invoice_messages >>> .el-form-item__label {
    min-width: 98px;
    text-align: left;
  }
  .add_invoice_messages >>> .el-input__prefix {
    left: 92%;
  }
  .change_width_upTime {
    display: flex;
    align-items: center;
  }
  .change_width >>> .el-form-item__label {
    width: 128px;
  }
  .change_width >>> .el-form-item__content {
    width: auto;
  }

  .add_invoice_message >>> .el-input__prefix {
    position: absolute;
  }
  /* .change_padding .is-success .el-date-editor--date:hover >>> .el-input__prefix{
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                          display: none;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        } */
  .change_flex >>> .el-form-item__content {
    float: none;
    display: flex;
    align-items: flex-end;
    font-size: 12px;
    color: #92a2bc;
  }
  .change_flex span {
    height: 26px;
  }
  .add_class >>> .el-form-item__label {
    min-width: 140px;
  }
  .add_class >>> .el-form-item__content {
    width: 336px;
  }
  .add_class >>> .el-date-editor.el-input {
    width: 336px;
  }
  .add_class >>> .el-input__prefix {
    left: 306px;
  }

  .add_bottom {
    margin-bottom: 10px;
  }
  .upload_box {
    width: 60px;
    height: 60px;
    margin-right: 6px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }
  .upload_box i {
    font-size: 20px;
    line-height: 60px;
    color: #7c969b;
  }
  .upload_box i:focus {
    color: #7c969b;
  }

  ::v-deep .el-table td:first-child {
    border-right: 0;
  }
  ::v-deep .el-table th:first-child {
    border-right: 0;
  }
  .add_invoice_messages li {
    float: left;
    width: 60px;
    height: 60px;
    margin-right: 12px;
    margin-left: -10px;
    background: #f7fafc;
    border-radius: 4px;
    border: 1px solid #e1e7ea;
  }

  .add_invoice_messages ul {
    margin-top: 10px;
  }

  .batch_button1 {
    background: #dae4e9;
    cursor: not-allowed;
  }
  .batch_button2 {
    background: #339c9b;
  }
  .table_oper .item_color_money_complete {
    color: #e1e7ea;
    cursor: not-allowed;
  }
  .select_class1 >>> .el-form-item__label {
    min-width: 96px !important;
  }
  .select_class2 >>> .el-form-item__label {
    min-width: 98px !important;
  }
  .red_warning_box {
    width: 485px;
    height: 40px;
    margin-bottom: 6px;
    margin-left: -15px;
    padding-left: 33px;
    background: #ffffff;
    box-shadow: 0px 2px 10px 0px rgba(12, 79, 71, 0.16);
    border-radius: 4px;
  }
  .red_warning_box1 {
    padding-bottom: 4px;
  }
  .red_warning_box i {
    margin-right: 2px;
    font-size: 16px;
    color: #fb6476;
  }
  .red_warning_box span {
    color: #fb6476;
  }
  .red_top {
    margin-top: 6px;
  }
  .search_condition >>> .el-form-item {
    margin-right: 43px !important;
  }

  .close_icon {
    position: absolute;
    top: -6px;
    left: 52px;
    display:flex;
    align-items:center;
    justify-content:center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fb6476;
  }
  .close_icon i {
    /* position: relative;
    top: -3px;
    left: 2px; */
    font-size: 8px;
    color: #ffffff;
  }

  @media screen and (max-width: 1664px) {
    .upload-form >>> .upload-demo {
      width: 200px;
    }
    .upload-form >>> .el-upload__tip {
      position: absolute;
      top: 14px;
      right: -26px;
      font-size: 12px;
      transform: scale(0.75);
    }
    .width_1000_right-gai {
      float: right;
      width: 306px;
      height: 293px;
      padding: 13px;
      background: #f7fafc;
      border-radius: 10px;
    }
    ::v-deep .addDialog .el-dialog {
      width: 750px;
      height: auto !important;
    }
    ::v-deep .addDialog1 .el-dialog {
      width: 750px;
      height: 470px;
    }
    .dialog-footer {
      margin-top: 5px;
      display: block;
    }
    .dialog-footer-close {
      margin-top: 1px;
      float: right;
    }
    .form_item1 {
      margin-left: 20px;
      margin-right: 20px;
      padding-top: 20px;
    }
    .form_item2 {
      margin-left: 20px;
      margin-right: 20px;
    }
    .form_item2 >>> .el-upload__tip {
      top: 5px;
      padding-left: 28px;
      width: 233px;
      /* margin-left: 5px; */
      /* margin-right: 20px; */
    }
    .form_item3 {
      margin-left: 20px;
      margin-right: 20px;
      padding-top: 200px;
    }
    .bg-purple-light1 {
      width: 330px;
      height: 310px;
      background: #f7fafc;
    }
    .bg-purple-light2-auto {
      height: auto !important;
    }
    .bg-purple-light2 {
      width: 340px;
      height: 310px;
      background: #f7fafc;
      border-radius: 10px;
    }
    .bg-purple-light3 {
      width: 350px;
      height: 360px;
      background: #f7fafc;
      border-radius: 10px;
    }
    .grid-content {
      border-radius: 10px;
      min-height: 36px;
    }
    ::v-deep .except_date_class .el-input__prefix {
      left: 90% !important;
    }
    ::v-deep .select_pay .el-input {
      width: 177px;
    }
    .invoiceUrlClass {
      margin-left: 93px;
      margin-top: -14px;
    }

    .close_icon {
      position: absolute;
      top: -6px;
      left: 32px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #fb6476;
      transform: scale(0.8);
    }
    .close_icon i {
      position: relative;
      top: -3px;
      left: 2px;
      font-size: 8px;
      color: #ffffff;
    }
    .search_invoice {
      margin-bottom: 11px;
    }
    .choose_invoice_title {
      line-height: 20px;
      margin-bottom: 9px;
    }
    .add_f {
      /* width: 67px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                height: 20px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                text-align: center;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                margin-right: 13px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                background: #339C9B;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                border-radius: 13px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                font-size: 12px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                color: #FFFFFF;     */
      margin-right: 9px;
      transform: scale(0.8);
    }
    .apply_box_top {
      display: flex;
      padding-top: 6px;
      margin-bottom: 16px;
    }
    .apply_box_top_left {
      width: 226px;
      height: 117px;
      margin-right: 40px;
      padding: 13px;
      background: #339c9b;
      box-shadow: 0px 6px 13px 0px rgba(47, 160, 159, 0.23);
      border-radius: 10px;
    }
    .apply_box_total {
      display: flex;
      align-items: center;
      width: 200px;
      height: 90px;
      padding-top: 13px;
      padding-bottom: 10px;
      padding-left: 10px;
      padding-right: 13px;
      background: rgba(237, 242, 245, 0.2);
      box-shadow: 0px 4px 8px 0px rgba(0, 102, 101, 0.23);
      border-radius: 10px;
    }
    .apply_box_total i {
      flex: 1;
      margin-right: 20px;
      color: #ffffff;
      font-size: 22px;
    }
    .echarts_box {
      height: 166px;
      margin-top: -26px;
    }
    .search_flex_box >>> .el-form-item {
      margin-bottom: 13px;
    }
    .search_flex_box >>> .el-form-item__content {
      float: left;
      width: 200px;
    }
    .search_flex_box >>> .el-input__inner {
      height: 20px;
    }
    .search_flex_box >>> .el-date-editor--daterange.el-input__inner {
      width: 214px;
    }
    .table_box_top {
      margin-bottom: 10px;
    }
    .table_total_data {
      font-size: 12px;
    }
    .add_invoice_messages >>> .el-form-item__label {
      min-width: 83px;
      text-align: left;
    }
    .add_invoice_messages >>> .el-input__prefix {
      left: 89%;
    }
    .change_width >>> .el-form-item__label {
      width: 108px;
    }
    .change_flex >>> .el-form-item__content {
      float: none;
      display: flex;
      align-items: flex-end;
      font-size: 12px;
      color: #92a2bc;
    }
    .change_flex span {
      height: 17px;
    }
    .add_class >>> .el-form-item__label {
      min-width: 93px;
    }
    .add_class >>> .el-form-item__content {
      width: 224px;
    }
    .add_class >>> .el-date-editor.el-input {
      width: 224px;
    }
    .add_class >>> .el-input__prefix {
      left: 204px;
    }
    .add_bottom {
      margin-bottom: 6px;
    }
    .upload_box {
      width: 40px;
      height: 40px;
      margin-right: 2px;
      background: #f7fafc;
      border-radius: 4px;
      border: 1px solid #e1e7ea;
    }
    .upload_box i {
      font-size: 14px;
      line-height: 40px;
      color: #7c969b;
    }
    .add_invoice_messages li {
      float: left;
      width: 40px;
      height: 40px;
      margin-right: 8px;
      background: #f7fafc;
      border-radius: 4px;
      border: 1px solid #e1e7ea;
    }
    .add_invoice_messages ul {
      margin-top: 6px;
    }
    .select_class1 >>> .el-form-item__label {
      min-width: 83px !important;
    }
    .select_class2 >>> .el-form-item__label {
      min-width: 81px !important;
    }
    .red_warning_box {
      width: 485px;
      height: 40px;
      margin-bottom: -8px;
      margin-left: -92px;
      padding-left: 22px;
      background: #ffffff;
      box-shadow: 0px 2px 10px 0px rgba(12, 79, 71, 0.16);
      border-radius: 4px;
      transform: scale(0.7);
      text-align: left;
    }
    .red_warning_box1 {
      padding-bottom: 3px;
    }
    .red_top {
      margin-top: 6px;
    }
    /* .red_warning_box2{
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                margin-bottom: -8px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            } */
    .search_condition >>> .el-form-item {
      margin-right: 12px !important;
    }
    .add_invoice_message {
      padding-left: 0px !important;
    }
    .inner_common_specials .el-form {
      padding-top: 0 !important;
    }
  }
</style>